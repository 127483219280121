<template>
  <div class="prescriptionConfig">
    <div class="spec">
      <div class="specification">
        <el-button type="primary" @click="addSpec"> 添加处方规范 </el-button>
        <div class="specificationList">
          <div class="title">规范</div>
          <div v-for="(item, index) in specList" :key="item.id">
            <div class="case">
              <div class="list" @click="viewSpecType(item)">
                {{ item.prescriptionSpecName }}
              </div>
              <div>
                <i
                  v-if="index !== 0"
                  class="el-icon-upload2"
                  @click="moveUp(item, index)"
                />
                <i
                  v-if="index !== specList.length - 1 && specList.length !== 1"
                  class="el-icon-download"
                  @click="moveDown(item, index)"
                />
                <i class="el-icon-edit" @click="editSpec(item)" />
                <i class="el-icon-delete" @click="deleteSpec(item)" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="specType">
        <specType
          :is-show-spec-type="isShowSpecType"
          :spec-type-list="specTypeList"
          @refreshTypeList="refreshTypeList"
          @viewSpecDetail="viewSpecDetail"
        />
      </div>
    </div>
    <template v-if="isShowDetail">
      <div class="SpecDetail">
        <specDetail
          :spec-detail-list="specDetailList"
          @refreshDetailList="refreshDetailList"
        />
      </div>
    </template>

    <el-dialog
      :title="titleType + '处方规范'"
      :visible.sync="dialogVisible"
      width="35%"
    >
      <el-form
        v-if="dialogVisible"
        ref="form1"
        label-position="right"
        :model="form1"
        :rules="rules"
        label-width="160px"
        @submit.native.prevent
      >
        <h3 v-if="isDelete" style="margin-bottom: 20px; text-align: center">
          确认删除<span style="color: red">{{
            specInfo.prescriptionSpecName
          }}</span
          >信息吗？
        </h3>
        <template v-if="!isDelete">
          <el-form-item label="处方审核规范编码:" prop="prescriptionSpecCode">
            <el-input
              v-model="form1.prescriptionSpecCode"
              v-check-permission
              placeholder="请输入处方审核规范编码"
              style="width: 80%"
              :disabled="titleType === '修改'"
              maxlength="50"
              @keyup.enter.native="saveSpec()"
            />
          </el-form-item>
          <el-form-item label="处方审核规范名称:" prop="prescriptionSpecName">
            <el-input
              v-model="form1.prescriptionSpecName"
              v-check-permission
              placeholder="请输入处方审核规范名称"
              style="width: 80%"
              maxlength="50"
              @keyup.enter.native="saveSpec()"
            />
          </el-form-item>
        </template>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveSpec()">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import SpecType from './component/specType';
import SpecDetail from './component/specDetail';
import { codeRule } from '@/utils/verificationRule';
import { mapActions, mapState } from 'vuex';
export default {
  components: {
    SpecType,
    SpecDetail,
  },
  data() {
    return {
      specDetailList: [], // 规范明细列表
      prescriptionTypeSpecCode: '', // 类型参数传递
      isShowSpecType: false, // 显示处方类型
      isShowDetail: false, // 显示明细
      dialogVisible: false,
      titleType: '',
      specInfo: '', // 编辑id
      isDelete: false, // 删除状态
      form1: {
        prescriptionSpecCode: '',
        prescriptionSpecName: '',
      },
      rules: {
        prescriptionSpecName: [
          {
            required: true,
            message: '请输入处方审核规范名称',
            trigger: 'blur',
          },
        ],
        prescriptionSpecCode: [
          { required: true, validator: codeRule, trigger: 'blur' },
        ],
      },
    };
  },
  computed: {
    ...mapState('prescriptionSpec', {
      specList: (state) => state.specList,
      specTypeList: (state) => state.specTypeList,
    }),
  },
  mounted() {
    this.getSpecList();
  },
  methods: {
    ...mapActions('prescriptionSpec', ['querySpecList', 'querySpecTypeList']),
    // 规范移动
    move(item, nextItem, type) {
      const param = {
        id: item.id,
        updateId: nextItem.id,
        updateWeight: nextItem.weight,
        weight: item.weight,
      };
      this.$api.specMoveUpOrDown(param).then((res) => {
        if (res.code === 0) {
          this.$message.success(type === 0 ? '上移成功' : '下移成功');
          this.getSpecList();
        }
      });
    },
    // 上移
    moveUp(item, index) {
      const { specList } = this;
      const nextItem = specList[index - 1];
      this.move(item, nextItem, 0);
    },
    moveDown(item, index) {
      const { specList } = this;
      const nextItem = specList[index + 1];
      this.move(item, nextItem, 1);
    },
    // 查询处方规范列表
    getSpecTypeList(item) {
      this.querySpecTypeList({ prescriptionSpecCode: item });
    },
    // 刷新类型列表
    refreshTypeList(code) {
      this.isShowDetail = true;
      this.getSpecTypeList(code);
    },
    // 查询处方规范列表
    getSpecList() {
      this.querySpecList({});
    },
    // 新增处方规范
    addSpec() {
      this.dialogVisible = true;
      this.isDelete = false;
      this.titleType = '新增';
      this.form1 = {
        prescriptionSpecCode: '',
        prescriptionSpecName: '',
      };
    },
    // 编辑处方规范
    editSpec(item) {
      this.dialogVisible = true;
      this.isDelete = false;
      this.titleType = '修改';
      this.form1 = {
        prescriptionSpecCode: item.prescriptionSpecCode,
        prescriptionSpecName: item.prescriptionSpecName,
      };
      this.specInfo = item;
    },
    deleteSpec(item) {
      this.dialogVisible = true;
      this.isDelete = true;
      this.titleType = '删除';
      this.specInfo = item;
    },
    // 保存处方规范
    saveSpec() {
      const { form1, titleType, specInfo, isDelete } = this;
      this.$refs['form1'].validate((valid) => {
        if (valid) {
          const param = {
            ...form1,
          };
          if (titleType !== '新增') {
            param.id = specInfo.id;
          }
          isDelete ? this.del(param) : this.editOrAdd(param);
        } else {
          return false;
        }
      });
    },
    // 编辑或新增
    editOrAdd(param) {
      this.$api.editSpec(param).then((res) => {
        if (res.code === 0) {
          this.$message.success(
            this.titleType === '修改' ? '修改处方规范成功' : '新增处方规范成功'
          );
          (this.dialogVisible = false), this.getSpecList();
        }
      });
    },
    // 删除
    del(param) {
      this.$api.deleteSpec(param).then((res) => {
        if (res.code === 0) {
          this.$message.success('删除处方规范字典成功');
          (this.dialogVisible = false), this.getSpecList();
        }
      });
    },
    // 查看处方类型
    viewSpecType(item) {
      this.isShowSpecType = true;
      this.isShowDetail = false;
      this.prescriptionTypeSpecCode = item.prescriptionSpecCode;
      this.getSpecTypeList(item.prescriptionSpecCode);
    },
    // 回调查看明细
    viewSpecDetail(item) {
      console.log(item, 'item00000');
      this.isShowDetail = true;
      this.getSpecDetail(item);
    },
    // 获取处方明细
    getSpecDetail(data) {
      console.log(data, '111111111113333');
      const param = {
        specTypeCode: data.specTypeCode,
        prescriptionSpecCode: data.prescriptionSpecCode,
      };
      this.$api.querySpecDetailList(param).then((res) => {
        this.specDetailList = res.data;
      });
    },
    // 回调明细
    refreshDetailList(data) {
      this.getSpecDetail(data);
    },
  },
};
</script>

<style lang="scss" scoped>
.spec {
  display: flex;
  justify-content: space-around;
  .specification {
    display: flex;
    flex-direction: column;
    align-items: center;
    .specificationList {
      width: 400px;
      margin-top: 10px;
      border: 1px solid #c0c4cc;
      .title {
        height: 40px;
        line-height: 40px;
        text-align: center;
        background-color: #fff;
      }
    }
  }
}
.case {
  display: flex;
  justify-content: space-between;
  // margin:0px 20px;
  padding: 0px 10px;
  line-height: 40px;
  border-bottom: 1px solid #c0c4cc;
}
.list {
  width: 300px;
}
</style>
